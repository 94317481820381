body {
  margin: 0;
  font-family: "Garamond";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  margin-top: 10px;
  color: white;
  background-color: rgb(6, 6, 160);
}
.header .release {
  font-weight: bold;
}
.header .title {
  font-weight: bolder;
  text-align: left;
  font-size: x-large;
  padding: 40px 30px 0 30px;
}
.header .title-wrap {
  display: flex;
  justify-content: space-between;
}
.header img {
  padding: 20px 30px 0 0;
  width: 80px;
  height: 80px;
}
.header-footer {
  justify-content: space-between;
  display: flex;
  text-align: left;
  padding: 8px 5px;
  font-weight: bolder;
  color: rgb(6, 6, 160);
  border-bottom: 4px solid rgb(6, 6, 160);
}
.header-footer .contact {
  text-align: left;
}
.header-footer .risk {
  text-align: right;
}
.mugshots {
  display: inline-block;
  align-content: center;
}
.top-info {
  margin: 20px
}
.scammer-title { 
  color:red;
  font-weight: bold;
  font-size: larger;
}
.top-info .title {
  font-size: x-large;
  font-weight: bolder;
}
.top-info .subtitle {
  font-size: large;
  font-weight: bolder;
  font-style: italic;
}
.counter {
  font-size: small;
}
.top-info .date {
  font-size: large;
  font-weight: bolder;
}

.mugshots img{
  margin: 0px 10px;
  width:300px;
  height: 300px;
  border: 3px solid black;
  cursor: url('./crosshair.png'), auto;
}
.mugshots {

}
.mug {
  display: inline-block;
  position: relative;
}
.mug .name {
  top: 280px;
  left:40px;
  font-weight: bolder;
  position: absolute;
  color: red;
}
.figure {
  padding: 20px;
  width: 80vw;
}
.figure {
  padding: 20px;
  width: 80vw;
}

.proof {
  padding: 20px;
  width: 70vw;
}
.apples-backing {
  padding: 20px;
  width: 70vw;
}

.important {
  margin-top: 30px;
  margin-left: 40px;
  font-weight: bolder;
  font-size: x-large;
}
.info {
  font-size: large;
  margin: 0px 20vw;
}
.scam {
  border: 2px solid black;

  max-width: 80vw;
}
.bye {
  padding: 30px;
  font-weight: bolder;
  font-size: x-large;
}